




import { Vue, Component } from 'vue-property-decorator';
import Transaction from '@/common/models/Transaction';
import API from '@/common/service/API';
import TransactionDetail from '@/components/TransactionDetail.vue';

@Component({
  name: 'TransactionView',
  components: {
    TransactionDetail,
  }
})
export default class TransactionView extends Vue {
  transaction = new Transaction();

  async created() {
    this.getTransasction();
  }

  async getTransasction() {
    const transaction = await API.Transaction.get(this.$route.params.uuid);
    if (transaction) {
      this.transaction = transaction;
    }
  }
}
