import Transaction from '../models/Transaction';
import walletDetailResponse from './wallet-detail.mock.json';

class TransactionApi {
  transactions: Transaction[] = walletDetailResponse.transactions.map((t) => {
    return new Transaction(t);
  });

  async get(uuid: string) {
    return this.transactions.find((t) => t.uuid === uuid);
  }

  async all() {
    return this.transactions;
  }
}

const API = {
  Transaction: new TransactionApi(),
};

export default API;
