























import { Vue, Component } from 'vue-property-decorator';

import { listWallets } from '@/common/service/WalletApi';
import moneyFormat from '@/common/functions/moneyFormat';

import QrCodeDialog from '@/components/QrCodeDialog.vue';
import { Wallet } from '@/common/models/Wallet';

@Component({
  name: 'WalletView',
  filters: {
    moneyFormat,
  },
  components: {
    QrCodeDialog,
  },
})
export default class WalletView extends Vue {
  wallets: Wallet[] = [];

  async created() {
    this.wallets = await listWallets();
  }
}
